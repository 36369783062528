<template>
    <div >
        <!-- TAB INPUTS -->
        <input id="tab-1" class="page-tab-input" name="page-tab-input" type="radio" checked />
        <input id="tab-2" class="page-tab-input" name="page-tab-input" type="radio" />

        <!-- TABS CONTAINER -->
        <div class="user-settings-header page-tabs-container">
            <label class="page-tab" for="tab-1">Discharge Report</label>
            <label class="page-tab" for="tab-2">Client's Note History</label>
        </div>

        <!-- CONTENT -->
        <section
            v-if="!loading"
            class="rec-data-div tabbed-page"
            :class="pageMeta.component_name.toLowerCase() + '-page'"
            v-disable-all="!pageMeta.permissions.edit"
        >
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br />
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>

                <h2 class="margin-0">{{ pageMeta.title }}</h2>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <!-- put generated code BELOW here -->

                <div v-if="this.dateEditing">
                    <div class="bottom-5">Editing the date</div>
                    <div class="flex center max500 bottom-20">
                        <input class="right-15" type="date" v-model="dischargeDate" :aria-required="true" contenteditable="false" onkeydown="return false" required  />
                        <input class="right-15" type="time" v-model="dischargeTime" :aria-required="true" contenteditable="false" onkeydown="return false" required  />
                        <button class="right-15" @click.prevent="toggleDateEditing">Done</button>
                        <span class="material-icons-outlined pointer" @click.prevent="toggleDateEditing">close</span>
                    </div>
                </div>
                <div class="dark-text bottom-0" v-else>
                    {{ dischargeDateFormatted }}
                    <span class="material-icons-outlined pointer" @click.prevent="toggleDateEditing">edit</span>

                </div>
                <p class="margin-0 dark-text">
                    <span v-if="this.rec.date">(Treatment plan {{ dateFormatted }})</span
                    ><span v-else>No treatment plan - </span>
                    <span v-if="this.sessionsEditing">
                        <span class="bottom-5"> Sessions Completed: </span>
                        <input style="width:36px;" type="number" v-model="rec.attended" />
                        <span class="bottom-5"> Sessions Missed: </span>
                        <input style="width:36px;" type="number" v-model="rec.notAttended" />
                        <span class="material-icons-outlined pointer" @click.prevent="toggleSessionsEditing">close</span>
                    </span>
                    <span v-else> Sessions Completed: {{ attended }}, Sessions Missed: {{ notAttended }}
                        <span class="material-icons-outlined pointer" @click.prevent="toggleSessionsEditing">edit</span>
                    </span>
                </p>
                <hr class="m-15" />
                <div class="align-right do-not-print-me">
                    <button class="secondary" type="button" @click="printView()">
                        Print View
                    </button>
                </div>
                <div class="dark-text header-w-bg">Discharge Report</div>
<!--                Discharge date needs to autofill with form creation date but it also needs to be editable and required.-->
                <div v-if="this.dischargeDateEditing">
                  <div class="bottom-5">Editing the Discharge Date</div>
                  <div class="flex center max500 bottom-20">
                    <input class="right-15" type="date" v-model="newDischargeDate" value="newDischargeDate" :aria-required="true" contenteditable="false" onkeydown="return false" required >
                    <input class="right-15" type="time" v-model="newDischargeTime"  value="newDischargeTime" :aria-required="true" onkeydown="return false" required />
                    <button class="right-15" @click.prevent="toggleDischargeDateEditing">Done</button>
                    <span class="material-icons-outlined pointer" @click.prevent="toggleDischargeDateEditing">close</span>
                  </div>
                </div>
                <div class="dark-text bottom-0" v-else>
                  <span>Discharge Date: </span>
                  {{ newDischargeDateFormatted }}
                  <span class="material-icons-outlined pointer" @click.prevent="toggleDischargeDateEditing">edit</span>

                </div>
                <Radios v-model="rec.transfer_summary" label="Is this a transfer summary?" :options="['No', 'Yes']" />
                <Radios v-model="rec.mandated_client" label="Is this a mandated client?" :options="['No', 'Yes']" />
                <Input type="text" v-model="rec.pacts_number" label="PACTS#" v-if="rec.mandated_client === 'Yes'" />
                <Input type="text" v-model="rec.dc_number" label="DC#" v-if="rec.mandated_client === 'Yes'" />

                <DiagnosisList
                    :useApiToRetrieveOptions="true"
                    :diag_codes="rec.diagnosis_at_discharge"
                    :modify-record='modifyRecord'
                    key-to-modify-by='diagnosis_at_discharge'
                    :use-treatment-plan='true'
                    :client_id="client_id"
                    :record_id="record_id"
                >Diagnosis at Discharge</DiagnosisList>

                <div class="dark-text header-w-bg">Initial Presenting Problem</div>
                <ExpandableTextArea
                    class="avoid-break"
                    inputClass="block do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    v-model="rec.problems"
                    label=""
                    :limit=500
                    inputStyle="min-height:20px;"
                />

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Other Agencies Involved</div>
                    <div class="flex colx2" v-for="(agency, idx) of rec.agencies" :key="'agency_' + idx">
                        <Input
                            class="flex-1 right-15 bottom-0"
                            type="text"
                            v-model="rec.agencies[idx].agency_name"
                            label="Agency Name"
                        />
                        <div class="block">
                            <ExpandableTextArea
                                inputClass="block right-30 do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                v-model="rec.agencies[idx].agency_plan"
                                label="Plan"
                                :limit=500
                                inputStyle="min-height:20px;"
                            />
                        </div>
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_agency(idx)">delete</span>
                        </button>
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addAgency()">
                        <span class="material-icons purple">add_box</span> Add Agency
                    </button>
                </div>

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Session Types Provided</div>
                    <div class="flex center top-10 bottom-10 session-types">
                        <Checkbox class="right-40" v-model="rec.individual" label="Individual" />
                        <Checkbox class="right-40" v-model="rec.group" label="Group" />
                        <Checkbox class="right-40" v-model="rec.couple" label="Couple" />
                        <Checkbox class="right-40" v-model="rec.family" label="Family" />
                    </div>
                </div>

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Reason for Discharge</div>
                    <ExpandableTextArea
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        v-model="rec.reason_for_discharge"
                        label=""
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Progress Made During Treatment</div>
                    <ExpandableTextArea
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        v-model="rec.progress_during_treatment"
                        label=""
                        :limit=500
                        inputStyle="min-height:20px;"
                    />

                    <div v-for="prog in rec.asam_progress" :key="prog.data_id">
                        <label for="'asam_prog_optn+prog'+data_id">ASAM Dimension
                        <Multiselect @input="msTreatmentInput(prog.data_id, $event)" :value="msTreatmentValue(prog.data_id)" :options="asam_options" track-by="id" label="text"></Multiselect>
                        </label>
                        <Input v-model="prog.notes" type="text" label="Notes" />
                        <button class="delete-icon no-bg dark-text pad-5" :id="'delete_asam_prog_'+prog.data_id">
                            <span class="material-icons-outlined" @click.prevent="removeTreatmentProgress(prog.data_id)">delete</span>
                        </button>
                    </div>

                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addTreatmentProgress()" id="add_treatment_prog">
                        <span class="material-icons purple">add_box</span> Add Treatment Progress per ASAM Dimension
                    </button>

                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Substance Abuse Details</div>
                    <div class="weight-600 dark-text bottom-5 ">Primary</div>
                    <div
                        class="flex bottom fullwidth"
                        v-for="(sub_abb, idx) of rec.substance_abuses"
                        :key="'sub_abb' + idx"
                        >
                        <Input
                            class="right-15 flex-1"
                            type="text"
                            v-model="rec.substance_abuses[idx].substance"
                            label="Substance"
                        />
                        <Input
                            class="right-15 flex-1"
                            type="text"
                            v-model="rec.substance_abuses[idx].start_age"
                            label="Start Age"
                        />
                        <Input
                            class="right-15 flex-1"
                            type="text"
                            v-model="rec.substance_abuses[idx].frequency"
                            label="Frequency of Past Use"
                        />
                        <Input
                            class="right-15 flex-1"
                            type="text"
                            v-model="rec.substance_abuses[idx].recent"
                            label="Frequency in Past 90 Days"
                        />
                        <Input
                            class="right-15 flex-1"
                            type="date"
                            v-model="rec.substance_abuses[idx].last_use"
                            label="Approximate Last Use"
                        />
                        <Input class="right-5" type="text" v-model="rec.substance_abuses[idx].method" label="Method" />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_sub_abuse(idx)">delete</span>
                        </button>
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addSubstance()">
                        <span class="material-icons purple">add_box</span> Add substance
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Toxicology Report Results</div>
                    <div
                        class="flex bottom-15"
                        v-for="(tox_repo, idx) of rec.drug_screenings"
                        :key="'tox_repo' + idx"
                    >
                        <Input
                            class="flex-1 right-15"
                            type="date"
                            v-model="rec.drug_screenings[idx].screening_date"
                            label="Screening Date"
                        />
                        <dropdown
                            class="flex-1 right-5"
                            v-model="rec.drug_screenings[idx].screening_result"
                            label="Result"
                            :options="['Positive', 'Negative']"
                        />
                        <ExpandableTextArea
                            class="flex-2 block left-10 right-5"
                            v-if="rec.drug_screenings[idx].screening_result === 'Positive'"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            v-model="rec.drug_screenings[idx].screening_substances"
                            label="Substances Detected"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_drug_screening(idx)">delete</span>
                        </button>
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addDrugScreening()">
                        <span class="material-icons purple">add_box</span> Add toxicology results
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Current Medications</div>
                    <div
                        class="flex center"
                        v-for="(medication, idx) in rec.medications"
                        :key="'medication_' + idx"
                        >
                        <ExpandableTextArea
                            class="block flex-1"
                            inputClass="flex do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            v-model="rec.medications[idx].text"
                            label="Medications"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_meds(idx)">delete</span>
                        </button>
                    </div>
                    <button
                        class="add-service_btn no-bg dark-text add"
                        @click.prevent="addMedications()"
                        v-show="rec.medications.length === 0"
                        >
                        <span class="material-icons purple">add_box</span>Add medications
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Changes In Living Situation After Treatment</div>
                    <div
                        class="flex center"
                        v-for="(change, idx) in rec.living_changes"
                        :key="'living_change' + idx"
                        >
                        <ExpandableTextArea
                            class="block flex-1"
                            inputClass="flex do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            v-model="rec.living_changes[idx].change"
                            label=""
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_living_sit(idx)">delete</span>
                        </button>
                        <button class="add-service_btn no-bg dark-text add" @click.prevent="addLivingSituation()">
                            <span class="material-icons purple">add_box</span> Add details
                        </button>
                    </div>
                </div>

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Changes in Employment After Starting Treatment</div>
                    <div
                        class="flex center"
                        v-for="(change, idx) in rec.employment_changes"
                        :key="'employment_changes' + idx"
                        >
                        <ExpandableTextArea
                            class="block flex-1"
                            inputClass="flex do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            v-model="rec.employment_changes[idx].change"
                            label=""
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_employment_change(idx)">delete</span>
                        </button>
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addEmpChanges()">
                        <span class="material-icons purple">add_box</span> Add details
                    </button>
                </div>

                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Referrals Suggested to Client During Treatment</div>
                    <div
                        class="flex bottom fullwidth max800"
                        v-for="(referral, idx) in rec.referrals"
                        :key="'referral' + idx"
                    >
                        <Input class="flex-1 right-15" type="text" v-model="rec.referrals[idx].name" label="Name" />
                        <Input class="flex-1 right-5" type="text" v-model="rec.referrals[idx].contact" label="Contact" />
                        <button class="delete-icon no-bg dark-text pad-5">
                            <span class="material-icons-outlined" @click.prevent="del_referral(idx)">delete</span>
                        </button>
                    </div>
                    <button class="add-service_btn no-bg dark-text add" @click.prevent="addReferal()">
                        <span class="material-icons purple">add_box</span> Add referral
                    </button>
                </div>
                <div class="avoid-break">
                    <div class="dark-text header-w-bg">Aftercare Plan</div>
                    <ExpandableTextArea
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        v-model="rec.aftercare_plan"
                        label=""
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </div>

                <div>
                    <label for="agree_ac_plan">
                        Did Client Agree to Aftercare Plan?
                        <Multiselect @input="acAgreeInput($event)" :value="acAgreeValue" :options="agree_ac_optns" :close-on-select="true" label="text" placeholder="Select" track-by="id" />
                        <TextArea v-model="rec.agree_ac_plan_reason" label="Reason" :limit=500 v-if="rec.agree_ac_plan === 2" />
                    </label>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="flex space-between">
                    <div>
                        <FormSignature
                            :user_id="user_id"
                            :signature_ids="signature_ids"
                            ref="form_signatures"
                            @getSignatures="getSignatures"
                            v-if="signature_ids.length > 0"
                        />
                        <FormSignatureButton
                            :rec="rec"
                            :original_rec="original_rec"
                            :signatures_info="signatures_info"
                            :fileName="pageMeta.component_name"
                            :signature_ids="signature_ids"
                            :record_id="record_id"
                            @resetView="reloadData"
                            @fail="fail2sign"
                            @showModal="showPdfModal"
                            :save="updateRecord"
                            >Sign and Submit</FormSignatureButton
                        >
                    </div>
                    <div class="align-right" v-if="!signature_ids.length">
                        <button
                            v-if="original_signature_ids.length && !signature_ids.length"
                            class="align-right secondary right-15"
                            type="button"
                            @click="signature_ids = original_signature_ids"
                        >
                            Cancel Edit
                        </button>
                        <ConfirmButton class="inline-block secondary right-15" @click="updateRecord()" :canedit="isEditable">Save as Draft</ConfirmButton>
                    </div>
                </div>
            </form>
            <div class="pad-20"></div>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </section>
        <section class="tabbed-page" v-disable-all="!pageMeta.permissions.edit">
            <h2>{{ this.client.last_name }}, {{ this.client.first_name }}</h2>
            <p>ID# {{ this.client.id }}, DOB {{ this.dob }}</p>
            <h3>View Previous Notes</h3>
            <p>
                Only signed and completed notes available for view, no drafts. If addendum needed for previous note, go
                to the client's completed forms in the<a class="weight-600">
                    <router-link :to="{ name: 'ClientDocuments', params: { client_id: this.client_id } }">
                        Documents & Forms tab of their profile
                    </router-link> </a
                >and select "see full list".
            </p>
            <div v-if="noteHistory.length < 1"><b>No previous Progress Notes on file for this client.</b></div>
            <div class="bottom-10 weight-600" v-for="(note, noteKey) in noteHistory" :key="'note_' + noteKey">
                <a href="" @click.prevent="getFile({ id: note.id })">
                    {{ note.dayt_create }} Progress Note. {{ client.last_name }}, {{ client.first_name }}.pdf
                </a>
            </div>
        </section>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import { generateId, calibrateMenu, saveFile } from '@/util/genericUtilityFunctions';
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import DiagnosisList from '@/components/general/Diagnosis/DiagnosisList';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    import { companies, documents, form_data, clients, file, appts } from '../../util/apiRequests';

    import dayjs from 'dayjs';
    import { openPdf } from '@/util/pdf';
    import { openImage } from '@/util/image';
    import DatePicker from "@/components/calendar/EventEditorModal/components/DatePicker";

    export default {
        name: 'Discharge',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: {DatePicker, FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, DiagnosisList, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,
                calibrateMenu,

                // client_data: { first_name: '', last_name: '' },

                dateEditing: false,
                dischargeDateEditing: false,
                sessionsEditing: false,
                noteHistory: [],
                asam_options: [
                    {text: 'Dimension One', id: 1},
                    {text: 'Dimension Two', id: 2},
                    {text: 'Dimension Three', id: 3},
                    {text: 'Dimension Four', id: 4},
                    {text: 'Dimension Five', id: 5},
                    {text: 'Dimension Six', id: 6},
                ],
                agree_ac_optns: [
                    {text: 'Yes', id: 1},
                    {text: 'No', id: 2},
                    {text: 'N/A', id: 3},
                ],
            };
        },
        methods: {
            modifyRecord(property, value) {
                this.$set(this.rec, property, value);
            },
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            toggleDateEditing() {
                this.dateEditing = !this.dateEditing;
            },
            toggleDischargeDateEditing() {
              this.dischargeDateEditing = !this.dischargeDateEditing;
            },
            toggleSessionsEditing() {
                this.sessionsEditing = !this.sessionsEditing;
            },
            addDiagnosis() {
                this.rec.diagnosis_at_discharge.push({
                    data_id: generateId(),
                    generated: true,
                    primary: this.rec.diagnosis_at_discharge.length === 0,
                    selected: {
                        abbrev: '',
                        id: 0,
                        service_code: ''
                    }
                });
            },
            addAgency() {
                this.rec.agencies.push({ agency_name: '', agency_plan: '' });
            },
            addSubstance() {
                const new_elem = {
                    substance: '',
                    recent: '',
                    last_use: '',
                    frequency: '',
                    method: '',
                    start_age: 0
                }

                if (!this.rec.substance_abuses){
                    this.$set(this.rec, 'substance_abuses', [new_elem]);
                } else {
                    this.rec.substance_abuses.push(new_elem);
                }
            },
            addMedications() {
                if (this.rec.medications.length === 0) {
                    this.rec.medications.push({ text: '' });
                }
            },
            addLivingSituation() {
                this.rec.living_changes.push({
                    change: '',
                    data_id: generateId()
                });
            },
            addEmpChanges() {
                this.rec.employment_changes.push({
                    change: '',
                    data_id: generateId()
                });
            },
            addReferal() {
                this.rec.referrals.push({
                    name: '',
                    contact: '',
                    data_id: generateId()
                });
            },
            addDrugScreening() {
                this.rec.drug_screenings.push({
                    screening_result: '',
                    screening_date: '',
                    screening_substances: ''
                });
            },
            del_referral(index) {
                this.rec.referrals = this.rec.referrals.filter((_, i) => i !== index);
            },
            del_agency(index) {
                this.rec.agencies = this.rec.agencies.filter((_, i) => i !== index);
            },
            del_sub_abuse(index) {
                this.rec.substance_abuses = this.rec.substance_abuses.filter((_, i) => i !== index);
            },
            del_drug_screening(index) {
                this.rec.drug_screenings = this.rec.drug_screenings.filter((_, i) => i !== index);
            },
            del_meds(index) {
                this.rec.medications = this.rec.medications.filter((_, i) => i !== index);
            },
            del_living_sit(index) {
                this.rec.living_changes = this.rec.living_changes.filter((_, i) => i !== index);
            },
            del_employment_change(index) {
                this.rec.employment_changes = this.rec.employment_changes.filter((_, i) => i !== index);
            },
            remove_dx(index) {
                this.rec.diagnosis_at_discharge = this.rec.diagnosis_at_discharge.filter((_, i) => i !== index);
                // if there is none of the diagnosis are a primary diagnosis, set the first one to primary
                const isAPrimary = this.rec.diagnosis_at_discharge.some((dx) => dx.primary);
                if (!isAPrimary) {
                    this.rec.diagnosis_at_discharge[0].primary = true;
                }
            },
            addTreatmentProgress(){
                const new_prog = {
                    data_id: generateId(),
                    selected: null,
                    notes: ""
                }

                if (!this.rec.asam_progress){
                    this.$set(this.rec, 'asam_progress', [new_prog]);
                } else {
                    this.rec.asam_progress.push(new_prog);
                }
            },
            removeTreatmentProgress(data_id){
                this.$set(this.rec,'asam_progress', this.rec.asam_progress.filter((prog) => prog.data_id !== data_id));
            },
            msTreatmentInput(data_id, value){
                this.$set(this.rec.asam_progress.find((prog) => prog.data_id === data_id), 'selected', value?.id ?? null);
            },
            msTreatmentValue(data_id){
                const value = this.rec.asam_progress.find((prog) => prog.data_id === data_id)?.selected;
                return this.asam_options.find((opt) => opt.id === value);
            },

            acAgreeInput(value){
                this.rec.agree_ac_plan = value.id
            },

            async getCounselorOptions() {
                try {
                    let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.counselorOptions = result.data.map((element) => {
                        return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                    });
                    this.counselorOptionsOriginal = this.counselorOptions;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of counselors.');
                }
            },
            async get_dx_codes() {
                const res = await this.$api.get(`/services/dx-codes`);
                let options = res.data?.map((element) => {
                    return { service_code: element.service_code, abbrev: element.abbrev, id: element.id };
                });
                this.options = options;
                this.originalOptions = this.options;
                this.rows = res.data;
                this.dx_codes = res.data.map((element) => {
                    element.selected = {
                        service_code: element.service_code,
                        abbrev: element.abbrev,
                        id: element.id
                    };
                    element.disabled = true;
                    return element;
                });
            },
            async get_service_codes() {
                const res = await this.$api.get(`/service_fees/list/company/${this.$store.state.user.company_id}`);
                this.service_options = res.data;
                this.service_options.filter((fee) => fee.code_type === 'service');
                this.original_service_options = this.service_options;
            },
            serviceCodeDesc({ service_code, abbrev }) {
                if (service_code && abbrev) {
                    return `${service_code} ${abbrev}`;
                }
                return 'Select an option';
            },

            async getNoteHistory() {
                try {
                    let criteria = {
                        filter: { 'files.client_id': [String(this.client_id)], file_kind: ['ProgressNote'] },
                        sort: { ['files.dayt_create']: 'Descending' },
                        page: {
                            num_per_page: 1000,
                            page_num: 1
                        }
                    };

                    const res = await this.$api.post(documents.getList(), { criteria: criteria });

                    if (res.data?.rows?.length > 0) {
                        this.noteHistory = res.data.rows.map((note) => {
                            note.dayt_create = dayjs(note.date).format('MM/DD/YY');
                            return note;
                        });
                    }
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of shared files');
                }
            },

            async getApptsHistory() {
                try {
                    const criteria = {
                        filter: { client_id: [String(this.client_id)] },
                        page: {
                            num_per_page: 1000,
                            page_num: 1
                        }
                    };

                    const res = await this.$api.post(appts.getList(), { criteria });

                    let attended = 0,
                        notAttended = 0;
                    res.data.rows.forEach((appt) => {
                        if (appt.appt_status === 'Attended') {
                            attended++;
                        } else if (appt.appt_status === 'Cancelled' || appt.appt_status === 'No Show') {
                            notAttended++;
                        }
                    });
                    this.attended = attended;
                    this.notAttended = notAttended;
                } catch (err) {
                    this.$toasted.error('Could not retrieve appointments history');
                }
            },

            async get_format_data() {
                const result = await this.$api.get(form_data.getForm(this.$route.params.record_id));
                const data = result.data.info;
                return JSON.parse(data[data.length - 1].raw_data);
            },

            async getFile(f) {
                if (f.id) {
                    let result = await this.$api.get(file.getFile(f.id));
                    if (result.data.file.file_type == 'pdf' || result.data.file.file_type == '.pdf') {
                        await openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                    } else if (result.data.file.originalContentType.includes('image')) {
                        openImage(result.data?.file?.Body);
                    } else {
                        saveFile(
                            result.data?.file?.Body,
                            result.data?.file?.originalContentType,
                            result.data?.file?.file_name
                        );
                    }
                } else {
                    this.$toasted.error('File not found');
                }
            },
            async init() {
                await Promise.all([this.getCounselorOptions(), this.get_service_codes(), this.get_dx_codes()]);

                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);

                await this.getNoteHistory();
                await this.getApptsHistory();
                // await this.get_client_info();

                if (this.$route.params.record_id != 0) {
                    const saved = await this.get_format_data();

                    if (!saved.agree_ac_plan){
                        saved.agree_ac_plan = 3;
                    }

                    if (!saved.agree_ac_plan_reason){
                        saved.agree_ac_plan_reason = "";
                    }

                    if (!saved.asam_progress){
                        saved.asam_progress = [];
                    }

                    this.rec = { ...this.rec, ...saved };
                } else {
                    const { data } = await this.$api(form_data.getTreatmentPlan(this.client_id));

                    const treatmentPlan = data?.length > 0 && data instanceof Array ? data[0].raw_data : {};

                    const { data: toxData }  = await this.$api(form_data.getToxReport(this.client_id));

                    const toxReport = toxData?.length > 0 && toxData instanceof Array ? toxData[0].raw_data : {};

                    const fieldsForDataId = ["goals", "agencies", "referrals", "medications", "living_changes", "employment_changes", "problem_array", "diagnosis_codes"]

                    fieldsForDataId.forEach(field => {
                        if (this.rec[field] && this.rec[field].length > 0) {
                            this.rec[field].forEach(item => {
                                item.data_id = item.data_id || generateId();
                            });
                        }
                    });

                    this.rec.attended = (this.$store.state.clientOverview.attendance) ? this.$store.state.clientOverview.attendance.attended : 0
                    this.rec.notAttended = (this.$store.state.clientOverview.attendance) ? this.$store.state.clientOverview.attendance.missed : 0

                    let toxDateSent = '';
                    const toxSubstances = [];

                    // Get data from certain fields in toxicology report
                    if (Object.keys(toxReport).length > 0) {
                        const { collectionDetails } = toxReport;
                        toxDateSent = collectionDetails.dateSent;

                        // Add substances that are positive
                        toxSubstances.push(...this.toxicologySubstances(toxReport));
                    }

                    this.rec = { ...this.rec, diagnosis_at_discharge: treatmentPlan.diagnosis_codes || [],
                        drug_screenings: [{
                            screening_date: toxDateSent || '',
                            screening_result: (toxSubstances.length > 0 ? "Positive" : (toxDateSent ? "Negative" : "")),
                            screening_substances : toxSubstances.toString().replace(/,/g, ", ") || ""
                        }],
                        ...treatmentPlan };
                }

                this.loading = 0;
            },
            toxicologySubstances(report) {
                // Gather all substances that are reported as positive
                const toxResultsPos = Object.entries(report.results).reduce((acc, [key, value]) => {
                    if (value === "Positive") acc[key] = value;
                    return acc;
                }, {});

                const toxResultKeys = Object.keys(toxResultsPos);

                // Filter out key codes of substances
                for (let tox in toxResultKeys) {
                    toxResultKeys[tox] = toxResultKeys[tox].replace(/^\S+(\/\S+)*\s-\s/g, '');
                }
                return toxResultKeys;
            },
            printView() {
                window.print();
            },
        },
        async created() {
            await this.init();
        },
        computed: {
            acAgreeValue(){
                return this.agree_ac_optns.find((opt) => opt.id === this.rec.agree_ac_plan);
            },
            dischargeDate: {
                get() {
                    return dayjs(this.rec.dischargeDate).format('YYYY-MM-DD');
                },
                set(value) {
                    // change the date to the new value, but keep the time
                    const newDate = dayjs(value);
                    const oldDate = dayjs(this.rec.dischargeDate);
                    this.rec.dischargeDate = newDate
                        .set('hour', oldDate.hour())
                        .set('minute', oldDate.minute())
                        .toDate();
                }
            },
            newDischargeDate: {
                get() {
              return dayjs(this.rec.newDischargeDate).format('YYYY-MM-DD');
                  },
                  set(value) {
                    // change the discharge date to the new value, but keep the time
                    const newDischargeDate = dayjs(value);
                    const oldDischargeDate = dayjs(this.rec.newDischargeDate);
                    this.rec.newDischargeDate = newDischargeDate
                        .set('hour', oldDischargeDate.hour())
                        .set('minute', oldDischargeDate.minute())
                        .toDate();
                  }
                },
            dob() {
                return this.client && this.client.dob ? dayjs(this.client.dob.split('T')[0]).format('MM/DD/YYYY') : '';
            },
            dischargeTime: {
                get() {
                    return dayjs(this.rec.dischargeDate).format('HH:mm');
                },
                set(value) {
                    // change the time to the new value, but keep the old date
                    const newDate = dayjs(this.rec.dischargeDate);
                    this.rec.dischargeDate = newDate
                        .set('hour', value.split(':')[0])
                        .set('minute', value.split(':')[1])
                        .toDate();
                }
            },
            newDischargeTime: {
                  get() {
                    return dayjs(this.rec.newDischargeDate).format('HH:mm');
                  },
                  set(value) {
                    // change the time to the new value, but keep the old date
                    const newDischargeDate = dayjs(this.rec.newDischargeDate);
                    this.rec.newDischargeDate = newDischargeDate
                        .set('hour', value.split(':')[0])
                        .set('minute', value.split(':')[1])
                        .toDate();
                  }
                },
            dx_model: {
                get() {
                    return this.rec.diagnosis_at_discharge.find((d) => d.primary).data_id;
                },
                set(value) {
                    this.rec.diagnosis_at_discharge = this.rec.diagnosis_at_discharge.map((d) => {
                        if (d.data_id === value) {
                            return { ...d, primary: true };
                        } else {
                            return { ...d, primary: false };
                        }
                    });
                }
            },
            dateFormatted() {
                return dayjs(this.rec.date).format('MM/DD/YYYY hh:mm A');
            },
            dischargeDateFormatted() {
                return dayjs(this.rec.dischargeDate).format('MM/DD/YYYY hh:mm A');
            },
            newDischargeDateFormatted() {
              return dayjs(this.rec.newDischargeDate).format('MM/DD/YYYY hh:mm A');
            },
            attended: {
                get() {
                    return (this.rec.attended) ? this.rec.attended : 0;
                },
                set(value) {
                    this.rec.attended = value;
                }
            },
            notAttended: {
                get() {
                    return (this.rec.notAttended) ? this.rec.notAttended : 0;
                },
                set(value) {
                    this.rec.notAttended = value;
                }
            }
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal) this.openModalOverlay();
                if (!newVal) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
